
import { Field, Button, Picker, Popup, Toast } from "vant";
import apiLogin from "@/api/login";
import { Encrypt } from "../../scripts/utils";

export default {
  components: {
    [Field.name]: Field,
    [Button.name]: Button,
    [Picker.name]: Picker,
    [Popup.name]: Popup,
  },
  data() {
    return {
      formDatas: {
        school: "",
        account: "",
        psw: "",
        code: "",
      },
      showPicker: false,
      columns: [],
      customFieldName: {
        text: "Name",
        id: "Id",
      },
      curOrgId: "",
      vCodeID: "",
      vCodeURL: "",
    };
  },
  mounted() {
    (this as any).getOrgList();
  },
  methods: {
    onConfirm(value: any) {
      (this as any).formDatas.school = value.Name;
      (this as any).curOrgId = value.Id;
      (this as any).getCode(value.Id);
      (this as any).showPicker = false;
    },
    async getOrgList() {
      const res = await apiLogin.listOrg();
      if ((res as any).Result) {
        (this as any).columns = (res as any).Datas;
      }
    },
    async getCode(id: any) {
      const res = await apiLogin.getCode(id);
      if ((res as any).Result) {
        (this as any).vCodeURL = `data:image/png;base64,${
          (res as any).Data.Data
        }`;
        (this as any).vCodeID = (res as any).Data.ID;
      }
    },
    async login() {
      if (
        (this as any).formDatas.school &&
        (this as any).formDatas.account &&
        (this as any).formDatas.psw &&
        (this as any).formDatas.code
      ) {
        const CryptoJS = require("crypto-js");
        const res = await apiLogin.login(
          (this as any).curOrgId,
          {
            name: (this as any).formDatas.account,
            pwd: CryptoJS.MD5((this as any).formDatas.psw).toString(),
            from: "31",
            code: (this as any).formDatas.code,
          },
          (this as any).vCodeID
        );
        if ((res as any).Result) {
          localStorage.setItem("Token", Encrypt((res as any).Data.Token));
          localStorage.setItem("OrgToken", Encrypt((this as any).curOrgId));
          localStorage.setItem(
            "UserInfo",
            Encrypt(JSON.stringify((res as any).UserInfo))
          );
          localStorage.setItem(
            "TeacherInfo",
            Encrypt(JSON.stringify((res as any).Teacher))
          );
          localStorage.setItem(
            "StudentInfo",
            Encrypt(JSON.stringify((res as any).Student))
          );
          (this as any).$router.push("/");
        } else {
          Toast.fail("操作失败了,请重新尝试！");
          (this as any).getCode((this as any).curOrgId);
        }
      } else {
        Toast.fail("请填写登录信息");
      }
    },
  },
};
