import { get, post } from '@/scripts/http';
import { Decrypt } from "@/scripts/utils";
const ORGTOKEN = localStorage.getItem("OrgToken") ? Decrypt(localStorage.getItem("OrgToken")) : '';
export default {
    async listOrg() {
        const res = await get(`/FJRH.RTM/ORGService/GetUnifyOrgsSelect`, {});
        return res;
    },
    async getCode(id: any) {
        const res = await get(`/${id}/FJRH.Campus/UserService/GetValidateCode?codelength=4`, {}, "https://rtm.fjrh.cn");
        return res;
    },
    async login(id: any, data: any, ex: any) {
        const res = await post(`/${id}/FJRH.Campus/UserService/Login`, data, ex, "", "https://rtm.fjrh.cn");
        return res;
    },
    //获取系统配置文件信息
    async GetConfigInfo() {
        const res = await get(`/${ORGTOKEN}/V4/Ctrl/GetConfigInfo`, {});
        return res;
    }
}